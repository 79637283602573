import React from 'react';


// Mock data simulating a JSON loaded from a static source
const productsData = [
  { id: 1, imageUrl: 'images/1.jpeg', price: 'Rs. 300' },
  { id: 2, imageUrl: 'images/2.jpeg', price: 'Rs. 250' },
  { id: 3, imageUrl: 'images/3.jpeg', price: 'Rs. 350' },
  { id: 4, imageUrl: 'images/4.jpeg', price: 'Rs. 275' },
  { id: 5, imageUrl: 'images/5.jpeg', price: 'Rs. 300' },
  { id: 6, imageUrl: 'images/6.jpeg', price: 'Rs. 250' },
];

// Header Component
const Header = () => (
  <header style={{ textAlign: 'center', marginBottom: '20px' }}>
    <img src="logo.png" alt="DEL ALMA" style={{ maxWidth: '20%', marginTop: '2%' }} />
    <body style={{ color: "#E1DDD8", marginTop: '5%', textAlign: 'start', fontSize: '20px' }}>Pick Pendant Type</body>
  </header>
);


// Product Card Component
const ProductCard = ({ imageUrl, price }) => (
  <div style={{ border: '1px solid #E1DDD8', padding: '20px', paddingBottom: '20px', borderRadius: '15px' }}>
    <img src={imageUrl} alt="Product" style={{ width: '100%', height: '90%', objectFit: 'cover', borderRadius: '10px' }} />
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <p style={{ textAlign: 'left' }}>{price}</p>
      <button style={{
        borderRadius: '15px',
        border: '0',
        backgroundColor: '#C1121F',
        color: '#E1DDD8',
        padding: '8px 16px'

      }}>Enquire now</button>
    </div>
  </div>
);


// Product Grid Component
const ProductGrid = ({ products }) => (
  <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '15px' }}>
    {products.map(product => (
      <ProductCard key={product.id} imageUrl={product.imageUrl} price={product.price} />
    ))}
  </div>
);


// // Pagination Component
// const Pagination = ({ currentPage, totalPages }) => (
//   <footer style={{ textAlign: 'center', marginTop: '20px' }}>
//     <span>Page {currentPage} of {totalPages}</span>
//   </footer>
// );

// Main App Component
const App = () => {
  //  const [currentPage, setCurrentPage] = useState(1);
  // const totalPages = 3; // Assuming there are 3 pages in total

  return (
    <div style={{ width: '80%', margin: 'auto', backgroundColor: '#000000', color: '#ffffff' }}>
      <Header />
      <ProductGrid products={productsData.slice(0, 6)} /> {/* Update slice as per actual pagination logic */}
      {/* <Pagination currentPage={currentPage} totalPages={totalPages} /> */}
    </div>
    // <div className="w-11/12 md:w-4/5 lg:w-3/4 xl:w-2/3 mx-auto bg-black text-white">
    //   <Header />
    //   <ProductGrid products={productsData.slice(0, 6)} />
    // </div>
  );
};

export default App;